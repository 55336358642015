import { useNavigate } from "react-router-dom";

import StepProgressBar from "react-step-progress";
import "react-step-progress/dist/index.css";
import SenderAndReceiver from "../pages/SenderAndReceiver";
import ShippingAndPackage from "../pages/ShippingAndPackage";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// const
import { componentTypes as types } from "../utils/const";

// context
import useCreateOrderContext from "../hooks/useCreateOrderContext";
import useAuthContext from "../hooks/useAuthContext";

import { createSingleOrder } from "../services";
import { useState } from "react";

const step1Content = <SenderAndReceiver />;
const step2Content = <ShippingAndPackage />;

function MyStepProgressBar(props) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    shippingCustomerCode,
    shippingOrderID,
    shippingServiceCode,
    shippingSalesPlatform,
    shippingWeightUnit,
    shippingDimensionUnit,
    warehouseCode,
    receiverName,
    receiverCompany,
    receiverCountry,
    receiverState,
    receiverCity,
    receiverAddress1,
    receiverAddress2,
    receiverAddress3,
    receiverPhone,
    receiverEmail,
    receiverPostcode,
    packages,
    validate,
  } = useCreateOrderContext();

  const { authentication } = useAuthContext();

  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const navigate = useNavigate();

  const step1Validator = () => {
    const senderValid = validate(types.VALIDATE_SENDER);
    const receiverValid = validate(types.VALIDATE_RECEIVER);
    return senderValid && receiverValid;
  };

  const step2Validator = () => {
    const shippingValid = validate(types.VALIDATE_SHIPPING);
    const packagesValid = validate(types.VALIDATE_PACKAGE);
    const goodsValid = validate(types.VALIDATE_GOODS);
    return packagesValid && shippingValid && goodsValid;
  };

  const onFormSubmit = () => {
    console.log("shippingServiceCode", shippingServiceCode);
    setErrorMessage("");
    const allValid = step2Validator();
    if (!allValid) {
      return;
    }

    let reqBody = {
      customerCode: shippingCustomerCode,
      orderId: shippingOrderID,
      serviceCode: shippingServiceCode,
      salesPlatform: shippingSalesPlatform,
      weightUnits: shippingWeightUnit,
      dimensionUnits: shippingDimensionUnit,
      sender: {
        warehouseCode,
      },
      receiver: {
        name: receiverName,
        company: receiverCompany,
        country: receiverCountry,
        province: receiverState,
        city: receiverCity,
        address: receiverAddress1,
        address2: receiverAddress2,
        address3: receiverAddress3,
        mobile: receiverPhone,
        email: receiverEmail,
        zipCode: receiverPostcode,
      },
      packageInfos: packages.map((pack) => {
        return {
          packageId: pack.packageID,
          weight: +pack.packageWeight,
          length: +pack.packageLength,
          width: +pack.packageWidth,
          height: +pack.packageHeight,
          goods: pack.goods.map((good) => {
            return {
              goodsCode: good.goodsCode,
              goodsName: good.goodsName,
              goodsCount: +good.goodsCount,
              worth: good.worth,
              currency: good.currency,
              weight: good.weight,
              length: good.length,
              width: good.width,
              height: good.height,
              countryOfOrigin: good.origin,
            };
          }),
        };
      }),
    };

    // console.log("reqBody", reqBody);

    createSingleOrder(reqBody, authentication)
      .then((response) => {
        // console.log("response", response);
        if (response?.data?.isSuccess || false) {
          navigate("/batch-order");
        }

        if (response.response && response.response.data.error) {
          setErrorMessage(response.response.data.error);
          handleSnackbarOpen();
        }

        if (response.data.error) {
          setErrorMessage(response.data.error);
          handleSnackbarOpen();
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <StepProgressBar
        startingStep={0}
        onSubmit={onFormSubmit}
        steps={[
          {
            label: "Sender & Receiver",
            subtitle: "50%",
            name: "step 1",
            content: step1Content,
            validator: step1Validator,
          },
          {
            label: "Package Details",
            subtitle: "100%",
            name: "step 2",
            content: step2Content,
            validator: step2Validator,
          },
          // {
          //   label: "Step 3",
          //   subtitle: "75%",
          //   name: "step 3",
          //   content: step3Content,
          //   validator: step3Validator,
          // },
          // {
          //   label: "Step 4",
          //   subtitle: "100%",
          //   name: "step 4",
          //   content: step4Content,
          //   validator: step4Validator,
          // },
        ]}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        {
          <Alert
            onClose={handleSnackbarClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        }
      </Snackbar>
    </>
  );
}

export default MyStepProgressBar;
